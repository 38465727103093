@use "../icons";
@use "../colors";
@use "../../libraryStyleOverrides/Graphs";
@use "../usageColors.scss";

svg.patterns {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;

  @media (max-width: 767px) {
    width: 12px;
    height: 12px;
  }

  @media (max-width: 565px) {
    width: 9px;
    height: 9px;
  }

  .baseline {
    line {
      stroke-width: 2.5;
    }
  }

  .baseline {
    line {
      stroke: colors.$primitive-darkPurple;
    }

    rect {
      fill: colors.$primitive-lightPurple;
    }
  }
}

.peakSavingsGraph {
  .fixed {
    width: 3.5rem;

    @media (max-width: 767px) {
      background-color: #fff;
      position: -webkit-sticky; /* Older Safari Versions */
      position: sticky;
      left: 0;
      z-index: 10;
    }
  }

  .units {
    text-align: right;
    padding-bottom: 1rem;
    padding-right: 0.65rem;
  }

  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }

  .eventDates,
  .eventCredits {
    td,
    th {
      padding: 0.65rem;
      text-align: center;
      font-weight: bold;

      @media (max-width: 1024px) {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
      }
    }
  }

  .eventCredits {
    th,
    td {
      border-top: 1px solid colors.$grey1;
    }

    th {
      text-align: right;
    }

    td {
      color: colors.$primitive-darkGreen;
    }
  }

  .peakSavingsEvents {
    height: 200px;
    font-weight: 600;
    fill: colors.$grey4;

    td,
    th {
      text-align: center;
      vertical-align: bottom;
      padding: 0 0.25rem 0 0;

      &.noData {
        font-weight: 400;
        vertical-align: middle;
      }
    }

    .graphAxis {
      text-align: right;
      height: 200px;
      font-weight: 500;
      padding-right: 0.5rem;

      div {
        height: 11%;
      }

      .minus {
        font-weight: 300;
      }
    }

    .peakSavingsEvent {
      height: 200px;

      @media (max-width: 1024px) {
        height: 200px;
        width: 40px;
      }

      @media (max-width: 992px) {
        height: 200px;
        width: 35px;
      }

      path.baseline {
        stroke: colors.$primitive-darkPurple;
        stroke-width: 2;
      }

      path.actual {
        stroke: colors.$white;
        stroke-width: 3;
        fill: colors.$primitive-darkPurple;
      }
    }
  }
}

@media (max-width: 767px) {
  .peakSavingsGraphWrapper {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 2rem;
      background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cline x1='0' y1='50%25' x2='100%25' y2='50%25' stroke='%23b1b3b3' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    $scrollLeft: icons.swap-color(icons.$linkArrowLeft, colors.$white, colors.$activeBlue);
    $scrollLeftHover: icons.swap-color(icons.$linkArrowLeft, colors.$white, colors.$brandBlue);

    $scrollRight: icons.swap-color(icons.$linkArrowRight, colors.$white, colors.$activeBlue);
    $scrollRightHover: icons.swap-color(icons.$linkArrowRight, colors.$white, colors.$brandBlue);

    &::-webkit-scrollbar-thumb {
      background-color: colors.$white;
      border: 2px solid colors.$activeBlue;
      color: colors.$activeBlue;
      background-image: $scrollLeft, $scrollRight;
      background-size: 1rem;
      background-repeat: no-repeat, no-repeat;
      background-position-x: 0.3rem, calc(100% - 0.3rem);
      background-position-y: 0.4rem;
      border-radius: 1rem;
      cursor: pointer;

      &:hover {
        border-color: colors.$brandBlue;
        background-image: $scrollLeftHover, $scrollRightHover;
      }
    }

    &::-webkit-scrollbar-button:horizontal {
      display: none;
    }
  }
}
