.downloadDataInfo {
  margin-top: 1rem;

  h3 {
    margin-bottom: 1rem;
  }

  .btn-primary {
    margin-top: 1rem;
    white-space: nowrap;
  }
}
