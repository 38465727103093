@use "../../libraryStyleOverrides/Button";
@use "../../libraryStyleOverrides/DatePicker";
@use "../../libraryStyleOverrides/Modal";

.report {
  button[name='submit'] {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h5 {
    margin-top: 0.5rem;
  }
}
