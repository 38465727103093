@use './colors';

// Master colors for usage graphs
$temperature: colors.$brandBlue;
$temperatureHigh: colors.$content-negative;
$temperatureLow: colors.$brandBlue;

$offpeak: #2da2af;
$superOffpeak: #1d661a;
$peak: #0c2340;
$otherRate: colors.$white;
