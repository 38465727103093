$brandBlue: #1e3575;
$activeBlue: #0072ce;
$black: #2b2b2b;
$grey4: #505357;
$grey3: #73767b;
$grey2: #b1b3b3;
$grey1: #d9d9d9;
$grey0: #f5f5f5;
$white: #ffffff;
$primitive-lightPurple: #c1a0da;
$primitive-purple: #6f5091;
$primitive-darkPurple: #523178;
$primitive-lightGreen: #d2d755;
// Adjusted slightly darker than the official primitive-green to meet WCAG contrast standards
$primitive-green: #839f14;
$primitive-darkGreen: #4a7729;
$inverse-interactive: #8dc8e8;
$content-negative: #e31937;
