@use '../components/colors';
@use '../components/icons';

body {
  .modal-backdrop {
    background-color: colors.$grey0;

    &.show {
      opacity: 0.96;
    }
  }

  .modal {
    .modal-lg {
      max-width: 80%;

      @media (max-width: 767px) {
        max-width: 90%;
      }

      margin-left: auto;
      margin-right: auto;
    }

    .modal-dialog {
      .modal-title {
        color: colors.$black;
        font-size: 2rem;
      }

      .modal-header {
        padding: 0;
        display: block;
        border: 0;

        .close {
          background-image: icons.$iconClose;
          background-repeat: no-repeat;
          background-size: 1.5rem;
          background-position-y: 0.5rem;
          margin: -3rem 0 0 auto;
          width: 1.2rem;
          height: 1.2rem;

          &:hover,
          &:active,
          &:focus {
            background-image: icons.$iconCloseHover;
            stroke: colors.$brandBlue;
            outline: none;
          }

          [aria-hidden='true'] {
            display: none;
          }
        }
      }

      .modal-content {
        border: 0;
        background-color: transparent;
        border-radius: 0;
      }

      .modal-body,
      .modal-footer {
        background-color: colors.$white;
        border: 0;
      }

      .modal-body {
        .list-group-item:first-child {
          border-radius: 0;
        }

        .list-group-item:last-child {
          border-radius: 0;
        }

        .list-group {
          margin-left: -1rem;
          margin-right: -1rem;
        }

        .row {
          margin-bottom: 1rem;
          margin-right: -30px;
        }
      }
    }
  }
}
