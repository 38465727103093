.copy-link {
  cursor: pointer;
  color: #0072CE;
}

.copy-link:hover, .copy-link:active {
  color: #1E3575;
}

.copy-icon {
  font-size: 1.5rem;
  padding-right: 0.25rem;
}

.finePrint {
  font-size: 0.75rem;
}

@media (min-width: 768px) {
  .copy-link {
    margin-left: -15px;
  }
}