.custom-tooltip {
  outline: none;

  border: 3px solid #fff;
  border-radius: 0.5rem;
  padding: 0;
  background-color: transparent;

  .inner-tooltip {
    border: 1px solid #0072ce;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin: 0;
    background-color: #f5f9fd;

    table {
      margin-top: 0.75rem;

      th {
        padding-right: 1rem;
      }
    }
  }
}
