@use "../components/colors";

body {
  .popover {
    font-size: 1rem;
    background-color: #f5f9fd;
    border-color: colors.$activeBlue;
    border-width: 2px;
    padding: 0.2rem;
  }

  .bs-popover-auto[x-placement^='top'] > .arrow::before,
  .bs-popover-top > .arrow::before {
    border-top-color: colors.$activeBlue;
  }

  .bs-popover-auto[x-placement^='top'] > .arrow::after,
  .bs-popover-top > .arrow::after {
    // Adjust bottom to control the border (bottom = border width + 1px)
    bottom: 3px;
    border-top-color: #f5f9fd;
  }
}
