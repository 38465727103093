@use "../icons";
@use "../colors";
@use "../../libraryStyleOverrides/Button";
@use "../usageColors";

.usageTableWrapper {
  @media (max-width: 767px) {
    overflow-x: auto;
  }

  &::-webkit-scrollbar {
    height: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cline x1='0' y1='50%25' x2='100%25' y2='50%25' stroke='%23b1b3b3' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  $scrollLeft: icons.swap-color(icons.$linkArrowLeft, colors.$white, colors.$activeBlue);
  $scrollLeftHover: icons.swap-color(icons.$linkArrowLeft, colors.$white, colors.$brandBlue);

  $scrollRight: icons.swap-color(icons.$linkArrowRight, colors.$white, colors.$activeBlue);
  $scrollRightHover: icons.swap-color(icons.$linkArrowRight, colors.$white, colors.$brandBlue);

  &::-webkit-scrollbar-thumb {
    background-color: colors.$white;
    border: 2px solid colors.$activeBlue;
    color: colors.$activeBlue;
    background-image: $scrollLeft, $scrollRight;
    background-size: 1rem;
    background-repeat: no-repeat, no-repeat;
    background-position-x: 0.3rem, calc(100% - 0.3rem);
    background-position-y: 0.4rem;
    border-radius: 1rem;
    cursor: pointer;

    &:hover {
      border-color: colors.$brandBlue;
      background-image: $scrollLeftHover, $scrollRightHover;
    }
  }

  &::-webkit-scrollbar-button:horizontal {
    display: none;
  }
}

.touUsageTable {
  line-height: 1.1;
  text-align: center;
  table-layout: fixed;
  width: 100%;
  min-width: 720px;
  border-collapse: separate;
  border-spacing: 0;

  @media (max-width: 767px) {
    .time {
      position: -webkit-sticky; /* Older Safari Versions */
      position: sticky;
      left: 0;
      z-index: 10;
    }
  }

  th,
  td {
    padding: 0.75rem;
    border: 0;
  }

  thead {
    @media (min-width: 768px) {
      position: -webkit-sticky; /* Older Safari Versions */
      position: sticky;
      top: 0;
    }
  }

  thead th {
    vertical-align: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    font-size: 1.1rem;
    white-space: nowrap;
    font-weight: 600;
    background-color: colors.$white;

    &.hasSubheader {
      padding-bottom: 0;
    }

    &.subheader {
      padding-top: 0;
    }
  }

  .temperature {
    white-space: nowrap;

    &.low span,
    &.high span {
      width: 3rem;
      text-align: center;
      display: inline-block;
    }

    &.low {
      padding-right: 0;
      text-align: right;
    }

    &.high {
      padding-left: 0;
      text-align: left;
    }
  }

  tbody {
    th {
      font-weight: normal;
      border: none;
    }

    .intervalDivider {
      td,
      th {
        border-bottom: 2px solid colors.$grey2;
      }

      &:last-child {
        td,
        th {
          border-bottom: none;
        }
      }
    }

    tr {
      // Stripe every other row of the main table body
      &:nth-child(odd) {
        th,
        td {
          background-color: colors.$grey0;
        }
      }

      &:nth-child(even) {
        th,
        td {
          background-color: colors.$white;
        }
      }

      // Small border at the end of the table when the last row is white
      &:last-child:nth-child(even) {
        border-bottom: 1px solid colors.$grey1;
      }
    }
  }

  .peak th,
  .peak td {
    font-weight: 600;
  }

  .note {
    font-size: 0.9rem;
    font-weight: normal;
  }

  // For the day and month interval tables, highlight the peak column
  &.hasPeakData {
    &.dayTable,
    &.monthTable {
      thead tr th.peak,
      tbody tr td.peak {
        font-weight: 600;
      }
    }
  }
}

.intervalButton.intervalButton {
  width: 100%;
  background-image: icons.$expand;
  background-repeat: no-repeat;
  background-size: 1rem;
  background-color: colors.$white;
  background-position: 1rem center;
  color: colors.$activeBlue;
  padding: 1rem 1rem 1rem 3rem;
  text-align: left;
  line-height: 1;
  height: auto;
  font-weight: 600;
  border: 1px solid colors.$grey1;

  &:hover,
  &:focus,
  &:active {
    background-color: colors.$white !important;
    border-color: colors.$grey1 !important;
    color: colors.$brandBlue !important;
    background-image: icons.$expandHover;
  }

  &.expanded {
    background-image: icons.$contract;
    background-color: colors.$brandBlue;
    border-color: colors.$brandBlue;
    color: colors.$white;

    &:hover,
    &:focus,
    &:active {
      background-image: icons.$contractHover;
    }
  }
}
