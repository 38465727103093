@use '../icons';
@use '../colors';
@use '../../libraryStyleOverrides/Graphs';
@use '../usageColors';

// Icons
$temperatureIcon: icons.$temperatureBase;
$electricUsageIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', usageColors.$offpeak);
$electricUsagePeakIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', usageColors.$peak);

body {
  .row.peakLegend {
    padding: 0;
    margin-left: 0;
    margin-right: 1rem;
    margin-bottom: -2.3rem;

    dl {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }

    @media (max-width: 767px) {
      height: auto;
      margin-bottom: 0;

      dd {
        margin-right: 1rem;
      }

      dt {
        width: 1rem;
        height: 1rem;
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    dt {
      display: inline-block;
      width: 1.5rem;
      height: 1rem;
    }

    dd {
      display: inline-block;
      margin: 0 3rem 0 0.5rem;
      padding-bottom: 0;
      line-height: 1;
      white-space: nowrap;
    }

    dd:last-of-type {
      margin-right: 0;
    }

    dt {
      svg {
        vertical-align: baseline;
        width: 1.5rem;
        height: 1.2rem;
        stroke-width: 1.5;
        margin-top: -2px;
      }

      &.actual svg > rect {
        stroke: colors.$white;
        fill: colors.$primitive-darkPurple;
      }

      &.baseline svg > rect {
        stroke: colors.$primitive-darkPurple;
      }
    }
  }

  .baseline .pattern-stripe-backward {
    rect {
      fill: colors.$primitive-lightPurple;
    }

    line {
      stroke: colors.$primitive-darkPurple;
      stroke-width: 4;
    }
  }
}
