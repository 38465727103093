@use "../icons";
@use "../colors";
@use "../usageColors";

// Icons
$rebateIcon: url("data:image/svg+xml,%3Csvg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.5 26C51.5 39.7195 40.2195 51.1524 26.5 51.1524C12.9329 51.1524 1.65244 39.7195 1.5 26.1524C1.5 12.2805 12.7805 1 26.5 1C40.2195 1 51.5 12.4329 51.5 26Z' stroke='%234A7729' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M26.5006 39.2642V44.2947' stroke='%234A7729' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M26.5006 8.01331V13.6535' stroke='%234A7729' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M34.1224 20.2087C34.1224 20.2087 33.6651 14.1111 26.9578 13.6538C20.2505 13.1965 19.3358 18.5319 19.4883 19.5989C19.4883 20.666 19.3358 24.6294 26.8053 26.6111C34.2748 28.5929 33.6651 33.0136 33.5126 33.6233C33.3602 34.2331 32.9029 38.8063 27.1102 39.2636C21.3175 39.7209 19.0309 34.0807 19.0309 33.166' stroke='%234A7729' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");

$electricReducedIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', colors.$primitive-darkGreen);

$comparePositiveIcon: url("data:image/svg+xml,%3Csvg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.5 26C51.5 39.7195 40.2195 51.1524 26.5 51.1524C12.9329 51.1524 1.65244 39.7195 1.5 26.1524C1.5 12.2805 12.7805 1 26.5 1C40.2195 1 51.5 12.4329 51.5 26Z' stroke='%234A7729' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M40.3645 16.5458L22.8624 37.3482L13.0613 28.6402' stroke='%234A7729' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
$compareNegativeIcon: url("data:image/svg+xml,%3Csvg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.5 26C51.5 39.7195 40.2195 51.1524 26.5 51.1524C12.9329 51.1524 1.65244 39.7195 1.5 26.1524C1.5 12.2805 12.7805 1 26.5 1C40.2195 1 51.5 12.4329 51.5 26Z' stroke='%23E31937' stroke-width='2' stroke-miterlimit='10'/%3E%3Cline x1='26' y1='10' x2='26' y2='35.4231' stroke='%23E31937' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M26.8846 41.1923C26.8846 41.5747 26.5747 41.8846 26.1923 41.8846C25.81 41.8846 25.5 41.5747 25.5 41.1923C25.5 40.81 25.81 40.5 26.1923 40.5C26.5747 40.5 26.8846 40.81 26.8846 41.1923Z' fill='%23E31937' stroke='%23E31937'/%3E%3C/svg%3E%0A");

.peakTimeSavingsSummary {
  background-color: colors.$grey0;
  margin: 0.5rem 0;
  padding: 1rem 1.5rem;
  text-align: center;

  .row {
    text-align: left;

    &.break-lg {
      @media (max-width: 991px) {
        display: inline-block;
      }
    }

    &.break-sm {
      @media (max-width: 767px) {
        display: inline-block;
      }
    }
  }

  .col-12 {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .icon {
    padding-top: 0.1rem;
    padding-left: 4rem;
    padding-right: 1rem;
    line-height: 1.4;
    background-size: 3rem;
    min-height: 4rem;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 0;

    &.comparePositive {
      background-image: $comparePositiveIcon;
    }

    &.compareNegative {
      background-image: $compareNegativeIcon;
    }

    &.rebate {
      background-image: $rebateIcon;
    }

    &.electricReduced {
      background-image: $electricReducedIcon;
    }
  }

  .value {
    font-size: 1.7rem;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 1.5rem;
    }

    @media (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .units {
    font-size: 1.25rem;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 1.1rem;
    }

    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
}
