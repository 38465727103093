@use "../colors";
@use "../icons";

.switchAccount {
  button.btn-link {
    padding: 0 0 0 1.5rem;
    background-image: icons.$makeSelection;
    background-size: 0.8rem;
    background-position: left 0;
    background-repeat: no-repeat;

    &:hover,
    &:active,
    &:focus {
      background-image: icons.$makeSelectionHover;
    }
  }
}

.selectAccounts {
  .accountNumber {
    padding-right: 2rem;
    font-weight: 600;
  }

  &:first-child {
    margin-top: -1rem;
  }

  &:last-child {
    margin-bottom: -1rem;
  }

  .list-group-item {
    background-color: colors.$white;
    border: 1px solid colors.$grey1;
    color: colors.$activeBlue;

    &:hover,
    &:active,
    &:focus {
      color: colors.$activeBlue;
      background-color: rgba(colors.$activeBlue, 0.08);
      border-color: colors.$activeBlue;
      outline: none;
    }

    &.disabled,
    &:disabled {
      background-color: colors.$brandBlue;
      border-color: colors.$brandBlue;
      color: colors.$white;
    }
  }
}
