@use '../components/colors';

body {
  .btn {
    font-size: 1.125rem;
    height: 40px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 0;
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn-primary {
    color: colors.$white;
    background-color: colors.$activeBlue;
    border-color: colors.$activeBlue;
  }

  .btn-primary:not(:disabled):not(.disabled):hover,
  .btn-primary:not(:disabled):not(.disabled):focus,
  .btn-primary:not(:disabled):not(.disabled):active {
    color: colors.$white;
    background-color: colors.$brandBlue;
    border-color: colors.$brandBlue;
  }

  .btn-outline-primary {
    color: colors.$activeBlue;
    background-color: colors.$white;
    border-color: colors.$activeBlue;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):hover,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled):focus {
    color: colors.$white;
    background-color: colors.$brandBlue;
    border-color: colors.$brandBlue;
  }
}
