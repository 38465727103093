@use '../icons';
@use '../colors';
@use '../../libraryStyleOverrides/Graphs';
@use '../usageColors';

// Icons
$temperatureIcon: icons.$temperatureBase;
$electricUsageIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', usageColors.$offpeak);
$electricUsagePeakIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', usageColors.$peak);

body {
  .row.legend {
    padding: 0;
    margin-left: 0;
    margin-right: 1rem;

    dl {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }

    @media (max-width: 767px) {
      height: auto;

      dd {
        margin-right: 1rem;
      }

      dt {
        width: 1rem;
        height: 1rem;
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    dd {
      display: inline-block;
      margin: 0 3rem 0 0.5rem;
      padding-bottom: 0;
      line-height: 1;
      white-space: nowrap;
    }

    dd:last-of-type {
      margin-right: 0;
    }

    dt {
      display: inline-block;
      width: 1.5rem;
      height: 1rem;

      svg {
        vertical-align: baseline;
        width: 1.5rem;
        height: 1.2rem;
        stroke-width: 1.5;
        margin-top: -2px;
      }

      &.temperature {
        width: 2.5rem;
        svg {
          width: 2.5rem;
        }
      }

      &.superOffpeak svg > rect {
        stroke: usageColors.$superOffpeak;
        fill: usageColors.$superOffpeak;
      }

      &.offpeak svg > rect {
        stroke: usageColors.$offpeak;
        fill: usageColors.$offpeak;
      }

      &.standard svg > rect {
        stroke: colors.$grey4;
        fill: usageColors.$otherRate;
      }

      &.noData svg > rect {
        stroke: colors.$grey3;
      }

      &.peak svg > rect {
        stroke: usageColors.$peak;
      }

      &.superOffpeak svg > rect {
        stroke: usageColors.$superOffpeak;
      }
    }
  }

  .noData .pattern-stripe-backward {
    rect {
      fill: colors.$grey0;
    }

    line {
      stroke: colors.$grey3;
      stroke-width: 4;
    }
  }

  .peak .pattern-stripe-forward {
    rect {
      fill: usageColors.$peak;
    }

    line {
      stroke: colors.$white;
      stroke-width: 4;
    }
  }
}
