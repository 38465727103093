@use "../../libraryStyleOverrides/Button";
@use "../../libraryStyleOverrides/Modal";

.modal.deleteLink {
  .modal-lg {
    max-width: 700px;

    @media (max-width: 767px) {
      max-width: 70%;
    }

    @media (max-width: 565px) {
      max-width: 90%;
    }

    margin-left: auto;
    margin-right: auto;
  }
}
