@use '../icons';
@use '../colors';
@use '../usageColors';

// Icons
$temperatureIcon: icons.$temperatureBase;
$electricUsageIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', colors.$grey3);
$electricUsagePeakIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', usageColors.$peak);
$electricUsageOffpeakIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', usageColors.$offpeak);
$electricUsageTotalIcon: icons.swap-color(icons.$electricUsageBase, '#4A7729', colors.$brandBlue);

$gasUsageIcon: icons.swap-color(icons.$gasUsageBase, '#000000', usageColors.$offpeak);

.usageSummary {
  background-color: colors.$grey0;
  margin: 0.5rem 0;
  padding: 1rem 1.5rem;
  text-align: center;

  .row {
    text-align: left;

    &.break-lg {
      @media (max-width: 991px) {
        display: inline-block;
      }
    }

    &.break-sm {
      @media (max-width: 767px) {
        display: inline-block;
      }
    }
  }

  .col-12 {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .icon {
    padding-top: 0.1rem;
    padding-left: 4rem;
    padding-right: 1rem;
    line-height: 1.4;
    background-size: 3rem;
    min-height: 4rem;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 0;

    &.offpeak {
      background-image: $electricUsageOffpeakIcon;
    }

    &.standard {
      background-image: $electricUsageIcon;
    }

    &.total {
      background-image: $electricUsageTotalIcon;
    }

    &.gas {
      background-image: $gasUsageIcon;
    }

    &.peak {
      background-image: $electricUsagePeakIcon;
    }

    &.temperature {
      background-image: $temperatureIcon;
    }
  }

  .value {
    font-size: 1.7rem;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 1.5rem;
    }

    @media (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .units {
    font-size: 1.25rem;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 1.1rem;
    }

    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
}
