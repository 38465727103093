@use "../components/colors";

body {
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    border-radius: 0;
    border: 1px solid colors.$grey3;
    font-weight: 600;
    color: colors.$grey3;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: colors.$brandBlue;
    border-color: colors.$brandBlue;
    color: colors.$white;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link:active {
    background-color: rgba(colors.$activeBlue, 0.08);
    color: colors.$activeBlue;
    border-color: colors.$activeBlue;
  }
}
