@use "../../libraryStyleOverrides/Button";
@use "../icons";
@use "../colors";

.header {
  background-color: colors.$brandBlue;
  padding: 2rem;

  .logo {
    display: block;
    width: 5.8rem;
    height: 2rem;
    background-image: icons.$logo;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h1 {
    white-space: nowrap;
    color: #fff;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  .link {
    padding: 0 0 0 1.5rem;
    background-image: icons.$iconBackArrow;
    background-size: 1rem;
    background-position: left 0.25rem;
    background-repeat: no-repeat;
  }

  a,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    color: #fff;
  }

  a:active,
  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

.menu {
  .navbar,
  .navbar-light {
    background-color: colors.$grey0;

    .navbar-nav {
      .nav-link {
        &,
        &:visited,
        &:active,
        &:focus {
          color: colors.$black !important;
          padding-left: 2rem;
          padding-right: 2rem;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    padding: 1.25rem;

    .logo {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      height: 1.375rem;
      width: 4.43rem;
    }

    h1 {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 575px) {
  .header {
    .logo {
      padding-top: 1rem;
      padding-bottom: 1.5rem;
    }

    h1 {
      text-align: center;
    }
  }
}
