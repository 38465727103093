@use "../../components/icons";
@use "../../components/colors";

.indented {
  margin-left: 6rem;
  margin-right: 6rem;

  @media (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
  }
}

body .btn-link.inlineLink {
  display: inline;
  border: 0;
  vertical-align: baseline;
  text-decoration: underline;
}
