@use '../icons';
@use '../colors';
@use '../../libraryStyleOverrides/Graphs';
@use '../usageColors.scss';

svg.patterns {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;

  @media (max-width: 767px) {
    width: 12px;
    height: 12px;
  }

  @media (max-width: 565px) {
    width: 9px;
    height: 9px;
  }

  .peak,
  .superOffpeak,
  .offpeak,
  .standard {
    line {
      stroke-width: 2.5;
    }
  }

  .peak {
    line {
      stroke: colors.$white;
    }

    rect {
      fill: usageColors.$peak;
    }
  }

  .noData {
    rect {
      fill: colors.$grey0;
    }
    &.offpeak line {
      stroke: usageColors.$superOffpeak;
    }

    &.offpeak line {
      stroke: usageColors.$offpeak;
    }

    &.peak line {
      stroke: usageColors.$peak;
    }

    &.standard line {
      stroke: colors.$grey3;
    }
  }
}

.comboGraph {
  height: 360px;
  font-weight: 600;
  fill: colors.$grey4;

  .minus {
    font-weight: 300;
  }

  .yAxisLabel,
  .yAxisLabel text {
    text-anchor: end;
  }

  .highTemperature {
    fill: usageColors.$temperatureHigh;
    stroke: colors.$white;
    stroke-width: 1.5;

    text {
      fill: colors.$grey4;
      stroke: none;
    }
  }

  .lowTemperature {
    fill: usageColors.$temperatureLow;
    stroke: colors.$white;
    stroke-width: 1.5;

    text {
      fill: colors.$grey4;
      stroke: none;
    }
  }

  .recharts-bar-rectangle {
    stroke-width: 1.5;

    .standard {
      &.data {
        fill: usageColors.$otherRate;
      }

      &.data,
      &.noData {
        stroke: colors.$grey4;
      }
    }

    .peak {
      &.data,
      &.noData {
        stroke: usageColors.$peak;
      }
    }

    .offpeak {
      &.data {
        fill: usageColors.$offpeak;
      }

      &.data,
      &.noData {
        stroke: usageColors.$offpeak;
      }
    }

    .superOffpeak {
      &.data {
        fill: usageColors.$superOffpeak;
      }

      &.data,
      &.noData {
        stroke: usageColors.$superOffpeak;
      }
    }
  }
}
