@use "../../libraryStyleOverrides/Tabs";

.usageOptions {
  select,
  select.monthPicker,
  .react-datepicker-wrapper,
  input[type='text'] {
    height: 42px;
    width: 12rem;

    @media (max-width: 575px) {
      width: 100%;
    }
  }

  .custom-control {
    padding-top: 10px;

    @media (max-width: 767px) {
      padding-top: 0;
    }
  }

  label,
  legend {
    white-space: nowrap;
    margin-bottom: 0;
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .col-auto {
    margin-right: 2rem;
    @media (max-width: 991px) {
      margin-right: 1.5rem;
    }

    @media (max-width: 800px) {
      margin-right: 1rem;
    }

    @media (max-width: 575px) {
      width: 100%;
    }
  }
}

.graphWarnings {
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;

  td {
    padding: 0.3rem 0.2rem;
    vertical-align: top;
  }

  .symbol {
    text-align: right;
  }

  .explanation {
    text-align: left;
  }

  .warningDetails {
    font-size: 0.9rem;

    .btn-link {
      font-size: 0.9rem;
      line-height: 1rem;
      text-decoration: underline;
      padding: 0;
      border: 0;
      margin-top: -0.2rem;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
