@use '../../components/icons';
@use '../../components/colors';

.savingsIntro {
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  margin-top: 3rem;
}

.savingTips {
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .tip {
    padding-bottom: 1em;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin: 0;

    @media (max-width: 767px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .inner {
      border: 1px solid colors.$grey1;
      margin: 0;
      padding: 1.5rem;
      height: 100%;

      .row {
        align-items: center;
        margin-bottom: 1.5rem;
      }

      img {
        height: 4rem;
      }

      p {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
