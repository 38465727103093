@use "../colors";

.landing-page-footer {
  color: white;
  text-align: center;
  background-color: #1e3575;
  padding-top: 1.5rem;
  padding-bottom: 4rem;

  .footer-links {
    display: inline-block;
    margin: 1.5rem auto;
    margin-bottom: 3rem;

    a {
      margin: 0 10px;
      font-weight: 600;
      color: white;

      &:hover,
      &:visited,
      &:active,
      &:focus {
        color: white;
      }

      &:active,
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    .separator {
      @media (max-width: 767px) {
        display: block;
        visibility: hidden;
      }
    }
  }
}
